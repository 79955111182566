import React from 'react';
import { Link } from "gatsby"

class Header extends React.Component {
    render() {
        return (
            <div className="mw7 center">
                <nav className="db dt-l w-100 border-box pv2">
                    <div className="db dtc-l w-100 w-50-l tc tl-l mb2 mb0-l">
                        <Link className="v-mid mid-gray link dim" to='/' title="Home">
                            <h1 className="ph3 ph0-l">Thousand Words</h1>
                        </Link>
                        {this.props.showSubtitle && <h4 className="ph4 ph0-l">We use public data to tell interactive visual stories about the world.</h4>}
                    </div>

                    <div className="db dtc-l v-mid w-100 w-50-l tc tr-l">
                        <Link className="link dim dark-gray f6 f5-l dib" to="/contact" title="Contact">Contact</Link>
                    </div>
                </nav>
            </div>
        )
    }
}

export default Header;