import React from 'react';
import SEO from "../components/seo"
import SubscribeBox from '../components/SubscribeBox/SubscribeBox'
import Header from '../components/Header/Header'
import { Link } from "gatsby"
import { graphql } from 'gatsby'
import Img from "gatsby-image"

class IndexPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      data: props.data['allPostsJson']['edges']
    }
  }

  render() {
    return (
      <div className="mw7 center">
        <SEO title="Thousand Words" description="We use public data to tell interactive visual stories about the world." />
        <Header showSubtitle={true} />
        <SubscribeBox />
        <section>
          {this.state.data.map((post) =>
            <article className="bt bb b--black-10" key={post.node.id}>
              <Link className="db pv4 ph3 ph0-l no-underline black dim" to={post.node.url}>
                <div className="flex flex-column flex-row-ns">
                  <div className="pr3-ns mb4 mb0-ns w-100 w-40-ns">
                    <Img fluid={post.node.img.childImageSharp.fluid} alt={post.node.alt} className="db" />
                  </div>
                  <div className="w-100 w-60-ns pl3-ns">
                    <h1 className="f3 fw1 mt0 lh-title">{post.node.title}</h1>
                    <p className="f6 f5-l lh-copy">
                      {post.node.description}
                    </p>
                    <p className="f6 lh-copy mv0">By {post.node.author}</p>
                    <time className="f6 db gray">{post.node.date}</time>
                  </div>
                </div>
              </Link>
            </article>
          )}
        </section>
        <footer className="pv4 ph3 ph5-m ph6-l mid-gray">
          <small className="f6 db tc">© 2020 <b className="ttu">Thousand Words</b>. All Rights Reserved</small>
          <div className="tc mt3">
            <Link to='/contact/' className="f6 dib ph2 link mid-gray dim">contact</Link>
          </div>
        </footer>
      </div>
    );
  }
}

export const query = graphql`
  query posts {
            allPostsJson {
            edges {
            node {
            id
          author
          date
          title
          url
          img {
            childImageSharp {
            fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid
          }
            }
          }
          alt
          isNew
          description
        }
      }
    }
  }  
`

export default IndexPage;
